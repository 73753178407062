import { gql } from "@apollo/client";
import { CollectionOrder, CollectionOwnershipLevel } from "@hex/common";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useTheme } from "styled-components";

import { HexNonIdealState } from "../../../../hex-components";
import {
  HomeTab,
  Routes,
  TabView,
  useQueryParams,
} from "../../../../route/routes";
import { useFeatureGates } from "../../../feature-gate/FeatureGateContext";
import {
  FeatureGateCTA,
  FeatureGateIcon,
} from "../../../feature-gate/FeatureGateZeroState";
import { CollectionIcon } from "../../../icons/CustomIcons";
import { ListSearchFilter } from "../../ListSearchFilter.js";
import { HomeTabTitle2 } from "../../shared/HomeTabTitle2.js";
import { HomeTabView, SelectTabView } from "../../shared/SelectSubView.js";
import { useSelectedSortCollectionOrder } from "../../useSelectedSortOrder.js";
import { NewCollectionDialog } from "../dialogs/NewCollectionDialog";

import { CollectionsList2 } from "./CollectionsList2.js";
import { useGetCollectionsForHome2Query } from "./CollectionsListTab2.generated.js";
import { SortByCollectionOrder } from "./SortByCollectionOrder.js";

export const COLLECTIONS_PER_REQUEST = 20;

gql`
  fragment CollectionForHomeFragment on Collection {
    id
    name
    description
    emoji
    canManage
    collectionHexLinkCountsByProjectType {
      projectCount
      componentCount
    }
    collectionGrants {
      id
      ...CollectionGrantFragment
    }
    starredByViewer
  }

  query GetCollectionsForHome2(
    $ownershipLevel: CollectionOwnershipLevel!
    $searchTerm: String
    $order: CollectionOrder
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
  ) {
    collections(
      ownershipLevel: $ownershipLevel
      searchTerm: $searchTerm
      order: $order
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          ...CollectionForHomeFragment
        }
      }
    }
  }
`;

export const CollectionsListTab2: React.ComponentType = React.memo(
  function CollectionsListTab2() {
    const { collections: collectionsFeatureGate } = useFeatureGates();

    const [searchTerm, setSearchTerm] = useState("");

    const history = useHistory();

    const theme = useTheme();

    const params = useParams<{
      tabView: HomeTab;
    }>();

    const { ownershipLevel, selectedTab } = useMemo(() => {
      switch (params.tabView) {
        case "you":
          return {
            ownershipLevel: CollectionOwnershipLevel.OWN,
            selectedTab: HomeTabView.YOU,
          };
        case "sharedWithYou":
          return {
            ownershipLevel: CollectionOwnershipLevel.SHARED,
            selectedTab: HomeTabView.SHARED_WITH_YOU,
          };
        case "sharedWithOrg":
          return {
            ownershipLevel: CollectionOwnershipLevel.ORG,
            selectedTab: HomeTabView.SHARED_WITH_ORG,
          };
        case "all":
        default:
          return {
            ownershipLevel: CollectionOwnershipLevel.ALL,
            selectedTab: HomeTabView.ALL,
          };
      }
    }, [params.tabView]);

    const collectionOrder = useSelectedSortCollectionOrder();
    const queryParams = useQueryParams();

    const setCollectionOrder = useCallback(
      (value: CollectionOrder) => {
        const newParams = {
          ...queryParams,
          sort: value.toLowerCase(),
        };
        Routes.push(history, Routes.HOME, {
          subView: "collections",
          tabView: selectedTab,
          queryParams: newParams,
        });
      },
      [history, queryParams, selectedTab],
    );

    const queryOptions = useMemo(
      () => ({
        ownershipLevel,
        searchTerm,
        order: collectionOrder,
        first: COLLECTIONS_PER_REQUEST,
        last: null,
        before: null,
        after: null,
      }),
      [collectionOrder, ownershipLevel, searchTerm],
    );

    const { data, error, fetchMore, loading } = useGetCollectionsForHome2Query({
      variables: queryOptions,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });

    const collections = useMemo(
      () => data?.collections.edges.map((edge) => edge.node) ?? [],
      [data?.collections.edges],
    );

    const loadMoreData = useCallback(
      () =>
        fetchMore({
          variables: {
            ...queryOptions,
            after: data?.collections.pageInfo?.endCursor,
          },
        }),
      [data, queryOptions, fetchMore],
    );

    const selectTabCallback = useCallback(
      (tabView: TabView) => {
        Routes.push(history, Routes.HOME, {
          subView: "collections",
          tabView,
        });
      },
      [history],
    );

    if (!collectionsFeatureGate) {
      return (
        <>
          <HomeTabTitle2
            icon={<CollectionIcon color="currentColor" />}
            iconColor={theme.bento.collections}
            text="Collections"
          />
          <HexNonIdealState
            $noBorder={true}
            action={<FeatureGateCTA featureGate="collections" />}
            description="Collections give you the ability to organize and share your projects. Upgrade your plan to use collections."
            icon={<FeatureGateIcon />}
            title="Upgrade plan to enable collections"
          />
        </>
      );
    }

    return (
      <>
        <NewCollectionDialog />
        <HomeTabTitle2
          icon={<CollectionIcon color="currentColor" />}
          iconColor={theme.bento.collections}
          leftAction={
            <div
              css={`
                padding-left: 12px;
              `}
            >
              <SelectTabView
                resourceName="collection"
                value={selectedTab}
                onChange={selectTabCallback}
              />
            </div>
          }
          rightAction={
            <>
              <SortByCollectionOrder
                collectionOrder={collectionOrder}
                onChange={setCollectionOrder}
              />
              <ListSearchFilter
                loading={loading}
                setAlreadyDebouncedFilter={setSearchTerm}
              />
            </>
          }
          text="Collections"
        />

        <CollectionsList2
          collections={collections}
          css={`
            margin-top: 10px;
            margin-bottom: 0;
          `}
          error={!!error}
          fetchMore={loadMoreData}
          filterApplied={!!searchTerm}
          hasMore={data?.collections.pageInfo?.hasNextPage ?? false}
          loading={loading}
        />
      </>
    );
  },
);
